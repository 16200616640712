export function formatNumber(value, decimal, autoDecimal = false) {
    if (autoDecimal) {
        // Convert the value to a string and find the decimal part
        const stringValue = value.toString();
        const decimalPart = stringValue.split('.')[1];

        if (decimal === undefined) {
            if (decimalPart) {
                // If there is a decimal part, use its length as the number of decimal places
                decimal = decimalPart.length;
            } else {
                // If there is no decimal part, default to 2 decimal places
                decimal = 2;
            }
        } else {
            if (decimalPart) {
                // If there is a decimal part, use its length as the number of decimal places
                if (decimalPart.length < 2) {
                    decimal = 2;
                } else if (decimalPart.length <= decimal) {
                    decimal = decimalPart.length;
                } else {
                    decimal = decimal;
                }
            } else {
                // If there is no decimal part, default to 2 decimal places
                decimal = 2;
            }
        }
    } else {
        // If autoDecimal is not enabled and decimal is undefined, default to 2 decimal places
        if (decimal === undefined) {
            decimal = 2;
        }
    }

    return value.toLocaleString("pt-BR", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal
    });
}

export function formatOnlyNumber(number) {
    if (number === null || number === undefined) {
        return number;
    }
    return number.replace(/[^\d]+/g, '');
}