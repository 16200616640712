import { takeLatest, put, call } from 'redux-saga/effects';

import { findUnidadeMedidaConversaoCadastro } from '../service';
import {
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS,
  FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE
} from '../actionTypes';

function* sagaFindUnidadeMedidaConversaoCadastro(action) {
  yield put({ type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_PENDING, id: action.id })

  try {

    const registro = yield call(findUnidadeMedidaConversaoCadastro, action.id);

    yield put({ type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_SUCCESS, registro: registro })

  } catch (error) {

    yield put({ type: FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO_FAILURE })
    // console.error(error) // eslint-disable-line

  }
}

export default function* watchFindUnidadeMedidaConversaoCadastro() {
  yield takeLatest(FIND_UNIDADE_MEDIDA_CONVERSAO_CADASTRO, sagaFindUnidadeMedidaConversaoCadastro)
}
